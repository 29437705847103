import React from "react"
import CloseSvg from "assets/svg/close.svg"
import styled from "styled-components"
import { mq, typography } from "styles"

const XIcon = styled(CloseSvg)`
  width: 11px;
  height: 11px;

  path {
    fill: ${({ theme }) => theme.localTheme?.ui?.icon?.opacityHex};
  }
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 10px;
  height: 40px;
  cursor: pointer;

  ${typography.bodySmall};

  &:hover {
    color: ${({ theme }) => theme.localTheme?.text?.secondary?.opacityHex};

    ${XIcon} {
      path {
        fill: ${({ theme }) => theme.localTheme?.ui?.iconHover?.opacityHex};
      }
    }
  }

  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};

  background-color: ${({ theme }) =>
    theme.localTheme?.canvas?.secondary?.opacityHex};

  ${mq.maxWidth("lg")} {
    display: none;
  }
`
type Props = {
  onClick: () => any
}

export const FilterTag: React.FC<Props> = ({ children, onClick }) => {
  return (
    <FilterContainer onClick={onClick}>
      {children}
      <XIcon />
    </FilterContainer>
  )
}
