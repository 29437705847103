import React, { useState, useEffect, useContext } from "react"
import { SanityCollectionTagList } from "typings/graphql"
import { Checkbox } from "components/UI/Checkbox"
import { ColorOptionUI } from "components/ColorOptions/ColorOptionUI"
import {
  FilterListContainer,
  FilterBar,
  Title,
  FilterBlock,
  MinusIcon,
  PlusIcon,
  TagItem,
  TagName,
} from "./Filter.styles"
import { ProductCollectionFilterContext } from "./ProductCollectionFilterContext"

export type FilterItemProps = {
  list: SanityCollectionTagList
}

export const FilterList = ({ list }: FilterItemProps) => {
  const [filterOpen, setFilterOpen] = useState(true)

  const { updateFilters, currentFilters, filterCounts } = useContext(
    ProductCollectionFilterContext
  )

  const toggleOpen = () => {
    setFilterOpen(!filterOpen)
  }

  useEffect(() => {
    setFilterOpen(list.defaultOpen)
  }, [])

  return (
    <FilterListContainer>
      <FilterBar
        onClick={toggleOpen}
        aria-label={
          filterOpen
            ? `Expand ${list.title} Section`
            : `Close ${list.title} Section`
        }
      >
        <Title>{list.title}</Title>
        {filterOpen ? (
          <MinusIcon role="img" aria-label="Minus Icon" />
        ) : (
          <PlusIcon role="img" aria-label="Plus Icon" />
        )}
      </FilterBar>
      <FilterBlock $display={filterOpen ? "block" : "none"}>
        {list?.items.map((tag, index) => {
          const selected = currentFilters[list.title]?.includes(
            tag.slug.current
          )
          return (
            <TagItem
              aria-pressed={selected}
              aria-label={`${tag.title}`}
              key={index}
              onClick={() => {
                updateFilters(tag.slug.current, list.title)
              }}
            >
              <TagName $colorTag={list.type === "color"}>
                {list.type === "color" && (
                  <ColorOptionUI filterSize={true} swatch={tag.swatch} />
                )}
                <span>
                  {tag.title} (
                  {
                    filterCounts.find(filter => filter.tag === tag.slug.current)
                      ?.count
                  }
                  )
                </span>
              </TagName>
              <Checkbox $selected={selected} />
            </TagItem>
          )
        })}
      </FilterBlock>
    </FilterListContainer>
  )
}
