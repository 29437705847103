import React from "react"

import ListIcon from "../../assets/svg/list-icon.svg"
import GridIcon from "../../assets/svg/grid-icon.svg"

import {
  ViewSelectorContainer,
  ViewSelectorOption,
} from "./ViewSelector.styles"
import { ViewType } from "typings/modules"

type ViewSelectorTypes = {
  view: ViewType
  onSetView: (view: ViewType) => void
}

export const ViewSelector: React.FC<ViewSelectorTypes> = ({
  view,
  onSetView,
}) => {
  return (
    <ViewSelectorContainer>
      <ViewSelectorOption
        $active={view === "list"}
        onClick={() => onSetView("list")}
      >
        <ListIcon /> List
      </ViewSelectorOption>
      <ViewSelectorOption
        $active={view === "grid"}
        onClick={() => onSetView("grid")}
      >
        <GridIcon /> Grid
      </ViewSelectorOption>
    </ViewSelectorContainer>
  )
}
