import React, { useContext, useState } from "react"
import {
  CloseButton,
  CloseIcon,
  FilterContainer,
  FilterHeader,
  FilterTitle,
  FilterButtons,
  FilterProductsButton,
  FilterClearAllButton,
} from "./Filter.styles"
import { FilterList } from "./FilterList"
import { Button } from "components/UI/Button/Button"
import { useCommonMediaQuery } from "hooks/useMediaQuery"
import { ProductCollectionFilterContext } from "./ProductCollectionFilterContext"
import { SortByList } from "./SortByList"

export type FilterCountType = {
  tag: string
  count: number
}

export const Filter = ({ backgroundColor }: { backgroundColor: string }) => {
  const { isDesktop, isMobile } = useCommonMediaQuery()
  const [showMobileFilter, setShowMobileFilter] = useState(false)
  const mobileFilterDisplay = showMobileFilter ? "block" : "none"
  const desktopFilterDisplay = showMobileFilter ? "none" : "block"

  const { tagList, clearAllFilters } = useContext(
    ProductCollectionFilterContext
  )

  return (
    <>
      <FilterContainer
        $bgColor={backgroundColor}
        $display={isDesktop ? "block" : mobileFilterDisplay}
      >
        <FilterHeader>
          <FilterTitle>{isMobile ? "Filter & Sort" : "Filter by"}</FilterTitle>
          <CloseButton
            aria-label="Close"
            onClick={() => setShowMobileFilter(!showMobileFilter)}
          >
            <CloseIcon />
          </CloseButton>
        </FilterHeader>
        {isMobile ? <SortByList /> : null}
        {tagList?.map((tagList, index) => (
          <FilterList key={index} list={tagList} />
        ))}
        <FilterClearAllButton
          $display={isDesktop ? "block" : desktopFilterDisplay}
          onClick={() => clearAllFilters()}
        >
          Clear All
        </FilterClearAllButton>
        <FilterButtons
          $bgColor={backgroundColor}
          $display={isDesktop ? "none" : "flex"}
        >
          <Button
            type="button"
            fullWidth
            onClick={() => clearAllFilters()}
            variant={"secondary-dark"}
          >
            Clear All
          </Button>
          <Button
            type="button"
            fullWidth
            onClick={() => setShowMobileFilter(!showMobileFilter)}
            variant="primary-dark"
          >
            View Results
          </Button>
        </FilterButtons>
      </FilterContainer>
      <FilterProductsButton>
        <Button
          type="button"
          fullWidth
          onClick={() => setShowMobileFilter(!showMobileFilter)}
        >
          {isMobile ? "Filter & Sort" : "Filter Products"}
        </Button>
      </FilterProductsButton>
    </>
  )
}
