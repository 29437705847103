import React from "react"
import { SanityProductCollection } from "typings/graphql"
import { ModeType } from "typings/modules"
import { ProductCollection } from "./ProductCollection"
import { LinkModeType } from "components/ProductItem/ProductItem"
import { ProductCollectionFilterProvider } from "./ProductCollectionFilterContext"

export const SanityProductCollectionBlock = ({
  fields,
}: {
  fields: SanityProductCollection
}) => (
  <ProductCollectionFilterProvider
    paginationCount={fields.paginationCount}
    items={fields.items}
    sortBy={fields.sortBy}
    tagList={fields.tags}
  >
    <ProductCollection
      mode={fields.mode as ModeType}
      linkMode={fields.linkMode as LinkModeType}
      backgroundColor={fields.bgColor.opacityHex}
      internalName={fields.internalName}
    />
  </ProductCollectionFilterProvider>
)

export default SanityProductCollectionBlock
