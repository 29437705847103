import React from "react"
import styled from "styled-components"
import { $Selected, $Hover } from "typings/modules"

type Props = $Selected & Partial<$Hover>

const Fill = styled.div``

const Container = styled.span<$Selected & $Hover>`
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.global.checkbox?.stroke.opacityHex};
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  ${({ $hover, $selected, theme }) =>
    $hover &&
    `
    border-color: ${
      theme.global.checkbox?.[$selected ? "activeHoverStroke" : "hoverStroke"]
        ?.opacityHex
    };
  `}

  ${Fill} {
    width: 12px;
    height: 12px;
    border-radius: 50%;

    ${({ $selected, theme }) =>
      $selected &&
      `
    background-color: ${theme.global.checkbox?.stroke.opacityHex};
  `}

    ${({ $hover, theme }) =>
      $hover &&
      `
    background-color: ${theme.dark.button?.secondary?.hoverFill?.opacityHex};
  `}
  }
`

export const Radio = ({ $selected, $hover }: Props) => {
  return (
    <Container
      aria-label="Radio"
      aria-pressed={$selected}
      $selected={$selected}
      $hover={$hover}
    >
      <Fill />
    </Container>
  )
}
