import queryString from "query-string"
import { ProductFilterType, ProductListItemType } from "typings/modules"
import { SanityCollectionTagList } from "typings/graphql"

const getParams = (tags?: Array<SanityCollectionTagList>) => {
  const searchParams = new URLSearchParams(window.location.search)
  // Exclude tags and filter options from param creation
  const excluded = [...tags.map(t => t.title), "base", "sortBy"]
  let params = ""
  searchParams?.forEach((value, key) => {
    if (!excluded.includes(key)) {
      params = `${params}&${key}=${value}`
    }
  })
  return params
}

export const clearFilterQueryUrl = (
  filters: ProductFilterType,
  tags?: Array<SanityCollectionTagList>
) => {
  if (Object.values(filters).flat().length <= 0) {
    const params = getParams(tags)
    const modifiedParams = params ? `?${params.substring(1)}` : ""
    window.history.pushState(
      { source: "filterContext" },
      "",
      `${window.location.pathname}${modifiedParams}`
    )
  }
}

export const updateFilterQueryUrl = (
  currentFilters: ProductFilterType,
  currentSort: string,
  baseFilter: string,
  tags?: Array<SanityCollectionTagList>
) => {
  if (typeof window !== "undefined") {
    const params = getParams(tags)
    let filters = queryString.stringify(currentFilters)
    if (filters) filters = `${filters}&base=${baseFilter}`
    const sortBy =
      currentSort && filters
        ? `&sortBy=${currentSort}`
        : currentSort
        ? `sortBy=${currentSort}`
        : ""
    window.history.pushState(
      { source: "filterContext" },
      "",
      `${window.location.pathname}?${filters}${sortBy}${params}`
    )
  }
}

export const sanitizeFilters = (
  filters: ProductFilterType,
  tags: Array<SanityCollectionTagList>
) => {
  Object.keys(filters).forEach(key => {
    if (!tags.some(tag => tag.title === key)) {
      delete filters[key]
      return
    }
    if (!Array.isArray(filters[key])) {
      filters[key] = [filters[key] as string]
    }
  })
  return filters
}

export const calculateFilterCounts = (
  tags: Array<SanityCollectionTagList>,
  allCollectionList: Array<ProductListItemType>,
  filteredCollectionList: Array<ProductListItemType>,
  baseFilter: string
) => {
  const baseCounts = tags
    .map(tagList => {
      return (
        baseFilter.includes(tagList.title) &&
        tagList.items.map(tag => {
          const tagCount = allCollectionList.filter(
            (productItem: ProductListItemType) =>
              productItem.item.tags
                .map(productTag => productTag.slug.current)
                .includes(tag.slug.current)
          ).length
          return { tag: tag.slug.current, count: tagCount }
        })
      )
    })
    .filter(Boolean)
    .flat()

  const filteredCounts = tags
    .map(tagList => {
      return (
        !baseFilter.includes(tagList.title) &&
        tagList.items.map(tag => {
          const tagCount = filteredCollectionList.filter(
            (productItem: ProductListItemType) =>
              productItem.item.tags
                .map(productTag => productTag.slug.current)
                .includes(tag.slug.current)
          ).length
          return { tag: tag.slug.current, count: tagCount }
        })
      )
    })
    .filter(Boolean)
    .flat()

  const totalCount = [...baseCounts, ...filteredCounts]
  return totalCount
}
