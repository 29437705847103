import { useProductList } from "hooks/useProductList"
import React, {
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useMemo,
} from "react"
import {
  SanityCollectionTagList,
  SanityContentItem,
  SanityContentItemOrProductItem,
  SanityDefinedColorOrImage,
} from "typings/graphql"
import {
  Orderable,
  ProductFilterType,
  ProductListItemType,
} from "typings/modules"
import {
  calculateFilterCounts,
  clearFilterQueryUrl,
  sanitizeFilters,
  updateFilterQueryUrl,
} from "utils/filterUtils"
import { tracking } from "utils/tracking"
import queryString from "query-string"
import {
  isSanityContentItem,
  isSanityProductItem,
  sortProductList,
} from "utils/productUtils"
import { FilterCountType } from "./Filter"
import { useLocation } from "react-use"

export const defaultHeaderHeight = 80

type TagItem = {
  title: string
  categoryTitle: string
  slug: string
  swatch?: SanityDefinedColorOrImage[]
}

type TagItems = {
  [key: string]: TagItem
}

type ProductCollectionFilterContextProps = {
  tagList: SanityCollectionTagList[]
  currentFilters: ProductFilterType
  activeTags: TagItem[]
  currentSort: string
  filterCounts: FilterCountType[]
  sortBy: string[]
  loading: boolean
  filteredItems: ProductListItemType[]
  scrolledItems: Orderable<ProductListItemType>[]
  contentItems: Orderable<SanityContentItem>[]
  setInView: Dispatch<SetStateAction<boolean>>
  updateFilters: (tagItemSlug: string, tagTitle: string) => void
  updateSort: (sortBy: string) => void
  clearAllFilters: () => void
  fetchMoreItems: () => void
  currentPaginationCount: number
  filterOrSortApplied: boolean
}

export const ProductCollectionFilterContext = createContext<ProductCollectionFilterContextProps | null>(
  null
)

type Props = {
  items: SanityContentItemOrProductItem[]
  tagList: SanityCollectionTagList[]
  paginationCount: number
  sortBy: Array<string>
  children: ReactNode
}

type ViewSettings = {
  currentFilters: ProductFilterType
  currentSort: string
  currentPaginationCount: number
  baseFilter: string
}

export const ProductCollectionFilterProvider = ({
  items,
  tagList,
  paginationCount,
  sortBy,
  children,
}: Props) => {
  const [viewSettings, setViewSettings] = useState<ViewSettings>({
    currentFilters: {},
    currentSort: sortBy[0],
    currentPaginationCount: paginationCount,
    baseFilter: "",
  })

  const [activeTags, setActiveTags] = useState<TagItem[]>([])
  const [filterCounts, setFilterCounts] = useState<FilterCountType[]>([])

  const [filteredItems, setFilteredItems] = useState<ProductListItemType[]>([])
  const [scrolledItems, setScrolledItems] = useState<
    Orderable<ProductListItemType>[]
  >([])
  const [inView, setInView] = useState(false)
  const productItems = items.filter(isSanityProductItem)
  const contentItems: Orderable<SanityContentItem>[] = items
    .filter(isSanityContentItem)
    .map(contentItem => ({
      ...contentItem,
      order: items.findIndex(item => item._id === contentItem._id),
    }))
  const { plItems, loading } = useProductList(productItems)
  const productListItems = useMemo(() => {
    return plItems.map(listItem => ({
      ...listItem,
      order: items.findIndex(item => item._id === listItem.item._id),
    }))
  }, [plItems])
  const location = useLocation()
  const tagItems: TagItems = {}

  tagList.forEach(tag => {
    tag.items.forEach(item => {
      if (item?.slug?.current) {
        tagItems[item.slug.current] = {
          title: item.title,
          categoryTitle: tag.title,
          slug: item.slug.current,
          swatch: item.swatch,
        }
      }
    })
  })

  const clearAllFilters = () => {
    clearFilterQueryUrl({}, tagList)
    setViewSettings({ ...viewSettings, currentFilters: {} })
    setActiveTags([])
  }

  const fetchMoreItems = () => {
    const newPaginationCount =
      viewSettings.currentPaginationCount + paginationCount
    setViewSettings({
      ...viewSettings,
      currentPaginationCount: newPaginationCount,
    })
  }

  const updateSort = (sort: string) => {
    setViewSettings({
      ...viewSettings,
      currentSort: sort,
    })
    tracking.filterSortClicked(sort)
  }

  const updateFilters = (tagItemSlug: string, filterGroup: string) => {
    const { currentFilters, baseFilter } = viewSettings
    const newFilters: ProductFilterType = currentFilters
    let newBase = baseFilter

    if (currentFilters[filterGroup]?.includes(tagItemSlug)) {
      newFilters[filterGroup] = (currentFilters[
        filterGroup
      ] as string[]).filter(tag => tag !== tagItemSlug)

      if (newFilters[filterGroup].length === 0) {
        delete newFilters[filterGroup]
      }

      setActiveTags(activeTags.filter(tag => tag.slug !== tagItemSlug))
    } else {
      newFilters[filterGroup] = newFilters[filterGroup]
        ? [...newFilters[filterGroup], tagItemSlug]
        : [tagItemSlug]

      setActiveTags([...activeTags, tagItems[tagItemSlug]])
    }
    const filters = { ...currentFilters, ...newFilters }
    if (
      Object.values(filters).length === 1 &&
      Object.keys(filters)[0] !== baseFilter
    ) {
      newBase = filterGroup
    }

    clearFilterQueryUrl(filters, tagList)
    setViewSettings({
      ...viewSettings,
      currentFilters: filters,
      baseFilter: newBase,
    })

    tracking.productFiltersSelected(filters)
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    if (
      location.trigger === "pushState" &&
      location.state?.source === "filterContext"
    ) {
      return
    }

    if (searchParams) {
      let sort = viewSettings.currentSort
      let base = viewSettings.baseFilter

      if (searchParams.get("sortBy")) {
        sort = searchParams.get("sortBy")
      }

      if (searchParams.get("base")) {
        base = searchParams.get("base")
      }

      const filters = sanitizeFilters(
        queryString.parse(window.location.search) as ProductFilterType,
        tagList
      )

      setActiveTags(
        Object.values(filters)
          .flat()
          .map(filter => tagItems[filter])
          .filter(tag => !!tag)
      )

      setViewSettings({
        ...viewSettings,
        currentSort: sort,
        baseFilter: base,
        currentFilters: filters,
      })
    }
  }, [location.search])

  useEffect(() => {
    if (inView && viewSettings.currentPaginationCount === 0) {
      setViewSettings({
        ...viewSettings,
        currentPaginationCount: paginationCount,
      })
    }
  }, [inView])

  useEffect(() => {
    if (
      !viewSettings.currentPaginationCount ||
      !productListItems?.length ||
      (loading && filteredItems.length)
    ) {
      return
    }

    const {
      currentFilters,
      currentSort,
      currentPaginationCount,
      baseFilter,
    } = viewSettings

    let collectionList = productListItems
    if (Object.values(currentFilters).flat().length > 0) {
      collectionList = productListItems.filter(product => {
        const filterCheck = Object.keys(currentFilters).map(key => {
          return product.item.tags?.some(tag =>
            currentFilters[key].includes(tag.slug.current)
          )
        })
        return filterCheck.every(Boolean)
      })
      updateFilterQueryUrl(currentFilters, currentSort, baseFilter, tagList)
    }

    setFilterCounts(
      calculateFilterCounts(
        tagList,
        productListItems,
        collectionList,
        baseFilter
      )
    )

    setFilteredItems(sortProductList(collectionList, currentSort))
    setScrolledItems(collectionList.slice(0, currentPaginationCount))
  }, [productListItems, loading, viewSettings])

  const filterOrSortApplied =
    activeTags.length > 0 ||
    Object.values(viewSettings.currentFilters).flat().length > 0 ||
    viewSettings.currentSort !== sortBy[0]

  return (
    <ProductCollectionFilterContext.Provider
      value={{
        tagList,
        currentFilters: viewSettings.currentFilters,
        activeTags,
        currentSort: viewSettings.currentSort,
        filterCounts,
        sortBy,
        loading,
        filteredItems,
        scrolledItems,
        updateFilters,
        updateSort,
        setInView,
        clearAllFilters,
        fetchMoreItems,
        currentPaginationCount: viewSettings.currentPaginationCount,
        contentItems,
        filterOrSortApplied,
      }}
    >
      {children}
    </ProductCollectionFilterContext.Provider>
  )
}

export const ProductCollectionFilterConsumer =
  ProductCollectionFilterContext.Consumer
