import React, { useContext, useEffect, useState } from "react"
import { ThemeContext, ThemeProvider } from "styled-components"
import InfiniteScroll from "react-infinite-scroll-component"
import { useInView } from "react-intersection-observer"
import { ProductListGrid } from "components/ProductList/ProductList.styles"
import {
  ResultsContainer,
  CollectionContainer,
  FilterTagColorSwatch,
  GridContainer,
  ProductCount,
  GridHeader,
  SortBy,
  SortByButton,
  SortByDropdown,
  SortItem,
  NoResults,
  NoResultsHeader,
  NoResultsText,
  FilterTagContainer,
  FilterTagClearAllButton,
} from "./ProductCollection.styles"
import { Filter } from "./Filter"
import { LinkModeType, ProductItem } from "components/ProductItem/ProductItem"
import { ModeType, ViewType } from "typings/modules"
import { Button } from "components/UI/Button/Button"
import { StyledArrow } from "components/UI/Dropdown/DropdownButton.styles"
import { FilterTag } from "./FilterTag"
import { ProductCollectionFilterContext } from "./ProductCollectionFilterContext"
import { ViewSelector } from "./ViewSelector"
import { tracking } from "utils/tracking"
import { ContentItem } from "components/ContentItem/ContentItem"

export const ProductCollection = ({
  mode,
  backgroundColor,
  linkMode,
}: {
  internalName: string
  mode: ModeType
  backgroundColor: string
  linkMode: LinkModeType
}) => {
  const [mobileView, setMobileView] = useState<ViewType>("grid" as ViewType)
  const [ref, inView] = useInView({ triggerOnce: true })
  const [sortDropdown, setSortDropdown] = useState(false)
  const themeContext = useContext(ThemeContext)
  const {
    activeTags,
    currentSort,
    sortBy,
    filteredItems,
    scrolledItems,
    updateFilters,
    updateSort,
    clearAllFilters,
    setInView,
    fetchMoreItems,
    currentPaginationCount,
    loading,
    contentItems,
    filterOrSortApplied,
  } = useContext(ProductCollectionFilterContext)

  useEffect(() => setInView(inView), [inView])

  const handleSetView = (view: ViewType) => {
    setMobileView(view)
    tracking.elementClicked(view, "Product List View Selector")
  }

  const isGrid = mobileView === "grid"

  return (
    <ThemeProvider
      theme={{
        localTheme: themeContext[mode],
      }}
    >
      <CollectionContainer $bgColor={backgroundColor}>
        <Filter backgroundColor={backgroundColor} />
        <ResultsContainer>
          <GridContainer
            $bgColor={backgroundColor}
            base="[12]"
            md="2 [12] 2"
            lg="2 [20] 2"
            ref={ref}
          >
            <GridHeader>
              <FilterTagContainer>
                <ProductCount>{filteredItems.length} Products</ProductCount>
                {activeTags.map(tagItem => (
                  <FilterTag
                    key={tagItem.slug}
                    onClick={() =>
                      updateFilters(tagItem.slug, tagItem.categoryTitle)
                    }
                  >
                    {!!tagItem.swatch?.length && (
                      <FilterTagColorSwatch
                        filterSize={true}
                        swatch={tagItem.swatch}
                      />
                    )}
                    <div>{tagItem.title}</div>
                  </FilterTag>
                ))}
                {activeTags.length >= 2 && (
                  <FilterTagClearAllButton onClick={clearAllFilters}>
                    Clear All
                  </FilterTagClearAllButton>
                )}
              </FilterTagContainer>
              <SortBy>
                <span>Sort by:</span>
                <SortByButton onClick={() => setSortDropdown(!sortDropdown)}>
                  {currentSort}
                  <StyledArrow $isFlipped={sortDropdown} />
                </SortByButton>
                <SortByDropdown $display={sortDropdown ? "block" : "none"}>
                  {sortBy.map((sort, index) => (
                    <SortItem
                      key={index}
                      onClick={() => {
                        updateSort(sort)
                        setSortDropdown(false)
                      }}
                    >
                      {sort}
                    </SortItem>
                  ))}
                </SortByDropdown>
              </SortBy>
              <ViewSelector view={mobileView} onSetView={handleSetView} />
            </GridHeader>
            {filteredItems.length > 0 ? (
              <InfiniteScroll
                dataLength={scrolledItems.length}
                next={fetchMoreItems}
                style={{ overflow: "inherit" }}
                hasMore={currentPaginationCount >= scrolledItems.length}
                loader={null}
              >
                <ProductListGrid
                  $display="grid"
                  base={isGrid ? "[1] [1]" : ""}
                  md="[7] 1 [7]"
                  xl="[5] 1 [5] 1 [5]"
                >
                  {new Array(currentPaginationCount).fill(0).map((_, index) => {
                    // Render only product items if filter or sort is applied
                    // (skipping content items)
                    if (filterOrSortApplied) {
                      const productItem = scrolledItems[index]
                      if (productItem) {
                        return (
                          <ProductItem
                            key={productItem?.item._id}
                            item={productItem}
                            linkMode={linkMode}
                            position={index}
                            mode={mode}
                            mobileView={mobileView}
                          />
                        )
                      }
                      return null
                    } else {
                      const contentItem = contentItems.find(
                        item => item.order === index
                      )
                      const productItem = scrolledItems.find(
                        item => item.order === index
                      )
                      if (contentItem) {
                        return (
                          <ContentItem
                            key={contentItem._id}
                            item={contentItem}
                          />
                        )
                      }
                      if (productItem) {
                        return (
                          <ProductItem
                            key={productItem?.item._id}
                            item={productItem}
                            linkMode={linkMode}
                            position={index}
                            mode={mode}
                            mobileView={mobileView}
                          />
                        )
                      }
                      return null
                    }
                  })}
                </ProductListGrid>
              </InfiniteScroll>
            ) : (
              !loading && (
                <NoResults>
                  <NoResultsHeader>No Results</NoResultsHeader>
                  <NoResultsText>
                    Please try removing some filters, or clearing all below.
                  </NoResultsText>
                  <Button
                    type="button"
                    fullWidth
                    onClick={clearAllFilters}
                    variant="secondary-dark"
                  >
                    Clear All
                  </Button>
                </NoResults>
              )
            )}
          </GridContainer>
        </ResultsContainer>
      </CollectionContainer>
    </ThemeProvider>
  )
}
