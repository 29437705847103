import { NoStyleButton } from "components/UI/Button/NoStyleButton"
import styled, { css } from "styled-components"
import { mq } from "styles"
import { typography } from "styles/typography"

export const ViewSelectorContainer = styled.div`
  display: flex;
  gap: 8px;

  ${mq.minWidth("md")} {
    display: none;
  }
`

export const ViewSelectorOption = styled(NoStyleButton)<{ $active: boolean }>`
  ${typography.bodySmall};
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  line-height: 1;
  opacity: 1;
  transition: all 0.3s ease;
  white-space: nowrap;
  padding: 10px 12px;
  border-radius: 5000px;
  border: 1px solid transparent;
  display: flex;
  align-self: center;
  ${({ $active }) =>
    $active
      ? css`
          border-color: ${({ theme }) =>
            theme.localTheme?.text?.primary?.opacityHex};
        `
      : css`
          color: ${({ theme }) =>
            theme.localTheme?.text?.secondary?.opacityHex};
          &:hover {
            border-color: ${({ theme }) =>
              theme.localTheme?.text?.secondary?.opacityHex};
          }
        `}

  svg {
    margin-right: 4px;
  }
`
