import styled from "styled-components"
import { $BgColor, $Mode } from "typings/modules"
import { mq, typography } from "styles"
import { Button } from "components/UI/Button/Button"

export const Container = styled.div<$Mode & $BgColor>`
  color: ${({ theme, $mode }) => theme[$mode].text.primary.opacityHex};
  background: ${({ $bgColor }) => $bgColor};
  position: relative;
  align-items: center;
  display: flex;
`

export const Headline = styled.p`
  ${typography.h4};

  margin-bottom: 8px;
`

export const Subcopy = styled.p`
  ${typography.bodySmall};
`

export const ButtonContainer = styled.div`
  margin-top: 32px;
`

export const StyledButton = styled(Button)`
  min-width: auto;
  padding: 0 22.5px;
`

export const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  .gatsby-image-wrapper {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }
`

export const Content = styled.div`
  z-index: 2;
  position: relative;
  padding: 64px 24px;

  ${mq.minWidth("md")} {
    padding: 120px 48px;
  }

  ${mq.minWidth("lg")} {
    padding: 32px;
  }
`

export const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`
