import styled from "styled-components"
import { typography, mq } from "styles"
import { NoStyleButton } from "components/UI/Button/NoStyleButton"
import CloseSvg from "assets/svg/close.svg"
import MinusSvg from "assets/svg/minus.svg"
import PlusSvg from "assets/svg/plus.svg"
import UnionSvg from "assets/svg/union.svg"
import { $BgColor, $Display } from "typings/modules"

type $ColorTag = {
  $colorTag?: boolean
}

export const FilterContainer = styled.div<$Display & $BgColor>`
  background: ${({ $bgColor }) => $bgColor};
  display: ${({ $display }) => $display};
  position: fixed;
  z-index: 1000;
  padding: 28px 30px 120px 30px;
  min-width: 100%;
  ${mq.minWidth("md")} {
    padding: 24px 48px 120px 48px;
  }
  ${mq.minWidth("lg")} {
    padding: 0 0 0 60px;
    position: relative;
    min-width: 300px;
    z-index: 1;
  }
  top: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
`

export const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid
    ${({ theme }) => theme.localTheme?.ui?.divider.opacityHex};
`

export const FilterTitle = styled.h2`
  ${typography.h4};
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  padding-bottom: 16px;

  ${mq.minWidth("lg")} {
    padding-top: 8px;
  }
`

export const FilterButtons = styled.div<$Display & $BgColor>`
  display: ${({ $display }) => $display};
  background: ${({ $bgColor }) => $bgColor};
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: space-between;
  z-index: 20001;
  padding: 16px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);

  button {
    margin-right: 16px;
  }
`

export const FilterListContainer = styled.div`
  border-bottom: 1px solid
    ${({ theme }) => theme.localTheme?.ui?.divider.opacityHex};
`

export const FilterBar = styled(NoStyleButton)`
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;

  ${mq.hasHover} {
    &:hover {
      opacity: 50%;
    }
  }
`

export const FilterLinkButton = styled(NoStyleButton)<Partial<$Display>>`
  display: ${({ $display }) => $display || "block"};
  ${typography.bodySmall};
  text-decoration: none;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  color: ${({ theme }) => theme.dark?.text?.primary?.opacityHex};
  transition: all 0.3s ease-out;

  &:hover {
    border-color: #ffffff00;
  }
`

export const FilterClearAllButton = styled(FilterLinkButton)`
  margin-top: 16px;
`

export const Title = styled.h5`
  ${typography.body};
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  flex: 1;
  font-weight: 700;
`

export const FilterBlock = styled.div<$Display>`
  display: ${({ $display }) => $display};
  margin-top: -7px;
  padding-bottom: 17px;
`

export const TagItem = styled(NoStyleButton)`
  width: 100%;
  overflow: hidden;
  padding: 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  ${typography.bodySmall};
  color: ${({ theme }) => theme.localTheme.text.primary.opacityHex};
`

export const TagName = styled.div<$ColorTag>`
  display: flex;
  padding-bottom: 6px;

  span {
    align-self: center;
  }

  ${({ $colorTag }) =>
    $colorTag &&
    `
      margin-left: -4px;
      margin-top: -2px;
  `}
`

export const FilterProductsButton = styled.div`
  padding: 0 30px 25px;
  ${mq.minWidth("md")} {
    padding: 0 96px 25px;
  }
  ${mq.minWidth("lg")} {
    padding: 0 0 25px;
    display: none;
  }
`

export const CloseButton = styled(NoStyleButton)`
  ${mq.minWidth("lg")} {
    display: none;
  }
  text-transform: uppercase;
  font-size: 15px;
  transition: opacity 0.3s ease;
  height: 100%;
  &:hover {
    opacity: 0.5;
  }
`

export const CloseIcon = styled(CloseSvg)`
  display: block;
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.dark?.ui?.icon?.opacityHex};
`

export const PlusIcon = styled(PlusSvg)`
  margin-right: 2px;
  color: ${({ theme }) => theme.localTheme?.ui?.icon?.opacityHex};
`

export const MinusIcon = styled(MinusSvg)`
  margin-right: 2px;
  color: ${({ theme }) => theme.localTheme?.ui?.icon?.opacityHex};
`

export const UnionIcon = styled(UnionSvg)`
  color: ${({ theme }) => theme.dark?.ui?.icon?.opacityHex};
  margin-right: 10px;
`
