import React, { useEffect, useRef } from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import Hls from "hls.js"

type Props = {
  playbackId: string
  autoplay: boolean
}

const Container = styled.div`
  display: flex;
  height: 100%;
`

const VideoContainer = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`

export const Video = ({ playbackId, autoplay = false }: Props) => {
  const videoRef = useRef(null)
  const [inViewRef, inView] = useInView()
  const src = `https://stream.mux.com/${playbackId}.m3u8`

  useEffect(() => {
    let hls: Hls
    const video = videoRef.current
    if (video) {
      video.muted = true
      if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = src
      } else if (Hls.isSupported()) {
        hls = new Hls()
        hls.loadSource(src)
        hls.attachMedia(video)
      } else {
        console.error("This is a legacy browser that doesn't support MSE")
      }
    }
    return () => {
      if (hls) {
        hls.destroy()
      } else if (video) {
        video.pause()
        video.src = ""
        video.load()
      }
    }
  }, [videoRef])

  useEffect(() => {
    const video = videoRef.current
    if (inView) {
      video?.play()
    } else {
      video?.pause()
    }
  }, [videoRef, inView])

  return (
    <Container ref={inViewRef}>
      <VideoContainer
        ref={videoRef}
        muted={autoplay}
        autoPlay={autoplay}
        playsInline={autoplay}
        loop={autoplay}
      />
    </Container>
  )
}

export default Video
