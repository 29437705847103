import React, { useState, useContext } from "react"

import {
  FilterListContainer,
  FilterBar,
  Title,
  FilterBlock,
  MinusIcon,
  PlusIcon,
  TagItem,
  TagName,
} from "./Filter.styles"
import { ProductCollectionFilterContext } from "./ProductCollectionFilterContext"
import { Radio } from "components/UI/Radio"

export const SortByList = () => {
  const [open, setOpen] = useState(true)

  const { sortBy, currentSort, updateSort } = useContext(
    ProductCollectionFilterContext
  )

  const toggleOpen = () => {
    setOpen(!open)
  }

  const title = "Sort By"

  return (
    <FilterListContainer>
      <FilterBar
        onClick={toggleOpen}
        aria-label={open ? `Expand ${title} Section` : `Close ${title} Section`}
      >
        <Title>{title}</Title>
        {open ? (
          <MinusIcon role="img" aria-label="Minus Icon" />
        ) : (
          <PlusIcon role="img" aria-label="Plus Icon" />
        )}
      </FilterBar>
      <FilterBlock $display={open ? "block" : "none"}>
        {sortBy.map((sortOption, index) => {
          const selected = currentSort === sortOption
          return (
            <TagItem
              aria-pressed={selected}
              aria-label={sortOption}
              key={index}
              onClick={() => {
                updateSort(sortOption)
              }}
            >
              <TagName>
                <span>{sortOption}</span>
              </TagName>
              <Radio $selected={selected} />
            </TagItem>
          )
        })}
      </FilterBlock>
    </FilterListContainer>
  )
}
