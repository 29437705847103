import React from "react"
import { SanityContentItem } from "typings/graphql"
import {
  Container,
  Headline,
  Subcopy,
  ButtonContainer,
  StyledButton,
  Content,
  ImageContainer,
  VideoContainer,
} from "./ContentItem.styles"
import { ButtonVariant } from "components/UI/Button/Button"
import { tracking } from "utils/tracking"
import { ModeType } from "typings/modules"
import { isSanityImageAsset, isSanityVideoAsset } from "utils/productUtils"
import { getImage } from "utils/imageUtils"
import { GatsbyImage } from "gatsby-plugin-image"
import Video from "components/UI/Video/Video"

type Props = {
  item: SanityContentItem
}

export const ContentItem: React.FC<Props> = ({ item }) => {
  const bgAsset = item.bgAsset?.[0]
  const image =
    bgAsset && isSanityImageAsset(bgAsset) ? getImage(bgAsset?.image) : null
  const imageAlt = image?.description
  const videoId =
    bgAsset && isSanityVideoAsset(bgAsset)
      ? bgAsset?.video?.asset?.playbackId
      : null

  return (
    <Container
      $mode={item.theme as ModeType}
      $bgColor={item.backgroundColor.opacityHex}
    >
      {image && (
        <ImageContainer>
          {image && (
            <GatsbyImage
              alt={imageAlt}
              image={image?.gatsbyImage}
              loading="lazy"
            />
          )}
        </ImageContainer>
      )}
      {videoId && (
        <VideoContainer>
          <Video playbackId={videoId} autoplay />
        </VideoContainer>
      )}
      <Content>
        {item.headline && <Headline>{item.headline}</Headline>}
        {item.subcopy && <Subcopy>{item.subcopy}</Subcopy>}
        {item.ctaLink && item.ctaText && (
          <ButtonContainer>
            <StyledButton
              to={item.ctaLink}
              type="button"
              variant={`primary-${item.theme}` as ButtonVariant}
              onClick={() => {
                tracking.elementClicked(item.internalName, "Product Collection")
              }}
            >
              {item.ctaText}
            </StyledButton>
          </ButtonContainer>
        )}
      </Content>
    </Container>
  )
}
