import styled from "styled-components"
import { typography, mq } from "styles"
import { NoStyleButton } from "components/UI/Button/NoStyleButton"
import { Grid } from "components/UI/Grid/Grid"
import { $BgColor, $Display } from "typings/modules"
import { FilterLinkButton } from "./Filter.styles"
import { ColorOptionUI } from "components/ColorOptions/ColorOptionUI"

export const CollectionContainer = styled.div<$BgColor>`
  background: ${({ $bgColor }) => $bgColor};
  padding: 64px 0;
  ${mq.minWidth("md")} {
    padding: 80px 0;
  }
  ${mq.minWidth("lg")} {
    padding: 96px 0;
    display: flex;
  }
`

export const FilterTagColorSwatch = styled(ColorOptionUI)`
  width: 18px;
  height: 18px;

  margin-left: -4px;
  margin-top: -6px;
`

export const GridContainer = styled(Grid)<$BgColor>`
  background: ${({ $bgColor }) => $bgColor};
  width: 100%;
  padding: 0 30px;

  ${mq.minWidth("md")} {
    padding: 0;
  }
`

export const ProductCount = styled.p`
  ${typography.body};
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  margin-right: 8px;
`

export const FilterTagContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  ${mq.minWidth("lg")} {
    & > div:nth-last-child(2) {
      margin-right: 8px;
    }
    min-height: 40px;
  }
`

export const FilterTagClearAllButton = styled(FilterLinkButton)`
  ${mq.maxWidth("lg")} {
    display: none;
  }
`

export const GridHeader = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;

  margin-bottom: 32px;
  ${mq.minWidth("md")} {
    margin-bottom: 64px;
  }
  ${mq.minWidth("lg")} {
    margin-bottom: 24px;
  }
`

export const SortBy = styled.div`
  display: none;

  ${mq.minWidth("md")} {
    ${typography.bodySmall};
    color: ${({ theme }) => theme.localTheme?.text?.secondary?.opacityHex};
    position: relative;
    display: inline-block;

    flex-shrink: 0;

    span {
      padding-right: 4px;
    }
  }

  ${mq.minWidth("lg")} {
    padding-top: 8px;
  }
`

export const SortByButton = styled(NoStyleButton)`
  ${typography.bodySmall};
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  display: inline-flex;
`

export const SortByDropdown = styled.div<$Display>`
  display: ${({ $display }) => $display};
  background-color: ${({ theme }) =>
    theme.localTheme?.canvas?.primary?.opacityHex};
  position: absolute;
  z-index: 100;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  padding: 16px 24px;
  right: 0;
  text-align: right;
  width: 152px;
`

export const SortItem = styled(NoStyleButton)`
  ${typography.bodySmall};
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  cursor: pointer;
  padding-bottom: 2px;

  ${mq.hasHover} {
    &:hover {
      opacity: 50%;
    }
  }
`

export const ResultsContainer = styled.div`
  display: block;
  width: 100%;
`

export const NoResults = styled.div`
  display: block;
  justify-self: center;
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  padding: 128px 0;
  width: 100%;
  ${mq.minWidth("md")} {
    padding: 192px 0;
    width: 384px;
  }
  ${mq.minWidth("lg")} {
    padding: 120px 0;
    width: 300px;
  }
  button {
    width: 180px;
    margin: 0 auto;
  }
`

export const NoResultsHeader = styled.h4`
  ${typography.h4};
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  text-align: center;
  padding-bottom: 12px;
  ${mq.minWidth("md")} {
    padding-bottom: 8px;
  }
`

export const NoResultsText = styled.p`
  ${typography.body};
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  text-align: center;
  padding-bottom: 30px;
`
